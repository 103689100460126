import React from 'react';
import styled from "styled-components";
import Logo from "../images/logo.png";

const Imprint = () => (
    <StyledImprint>
        <div className="imprint__logo">
            <img src={Logo} />
        </div>
        <b>IMPRESSUM</b>
        <br /><br />
        Verantwortlich:<br />
        Dr. med. Karsten Brauns<br />
        Lohstraße 431785 Hameln<br />
        T 05151-41424 <br />
        M mail@dr-brauns.de<br />
        <br /><br />
        Gesetzliche Berufsbezeichnung:<br />
        Dr. med. Karsten Brauns: Facharzt für Frauenheilkunde und Geburtshilfe (verliehen in der Bundesrepublik Deutschland durch die Landesärztekammer Berlin)
        Birgit Brauns: Fachärztin für Frauenheilkunde und Geburtshilfe
        (verliehen in der Bundesrepublik Deutschland durch die Landesärztekammer Niedersachsen)
        <br /><br />
        Zuständige Ärztekammer:<br />
        Landesärztekammer Niedersachsen, Karl-Wiechert-Allee 18-22, 30625 Hannover, Tel. 0511/380-02, info@aekn.de
        <br /><br />
        Zuständige kassenärztliche Vereinigung:<br />
        Kassenärztliche Vereinigung Niedersachsen - Landesstelle - Berliner Allee 22, 30175 Hannover, Tel. 0511/380-4800, info@kvn.de
        Kassenärztliche Vereinigung Niedersachsen - Bezirksstelle Hannover, Schiffgraben 26, 30175 Hannover, Tel. 0511/380-4800, kvn.hannover@kvn.de
        <br /><br />
        Berufsrechtliche Regelungen:<br />
        Abrufbar über die Website der Ärztekammer Niedersachsen, Bereich Medizin und Recht (www.aekn.de)
        <br /><br />
        Haftungsausschluss: Für die Inhalte externer Seiten wird keine Haftung übernommen, hierfür sind ausschließlich deren Betreiber verantwortlich.
    </StyledImprint>
)

const StyledImprint = styled.div`
  padding: 2rem 3rem 3rem;
  
  .imprint__logo img {
          width: 200px;
          margin-bottom: 1rem;
          margin-left: -0.5rem;
  }
`;

export default Imprint;


